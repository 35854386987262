import { useCallback, useEffect } from "react";
import { KardzVis } from "./kardz_vis";
import Toolbar from "./toolbar";
import { get_index_list } from "../store/actions";
import { useKardzAppDispatch } from "../store/store";

export function Kardz() {
  const dispatch = useKardzAppDispatch();
  const dispatch_action = useCallback((action: any) => dispatch(action), []);

  useEffect(() => {
    dispatch_action(get_index_list());
  }, []);

  return (
    <>
      <div
        className="flex flex-1 flex-row items-center justify-center h-full w-full object-cover bg-no-repeat"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundImage: `url(
            https://images.unsplash.com/photo-1569498317468-69bd8d0cf477?crop=entropy&cs=srgb&fm=jpg&ixid=M3w0Nzg4MTl8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MDcwNzA5Mjd8&ixlib=rb-4.0.3&q=85)`,
        }}
      >
        <div className=" flex flex-col items-center justify-center">
          <h1 className="p-2 text-6xl font-extrabold text-gray-900 dark:text-white">
            <span className="bg-gradient-to-r from-sky-400 to-emerald-600 bg-clip-text text-transparent">
              Kardz
            </span>
          </h1>
          <Toolbar />
          <KardzVis />
        </div>
      </div>
    </>
  );
}
