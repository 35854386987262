import data from "../../data/raw/custom_text.json";
import {
    kardz_set_card_name,
    kardz_update_index_list,
    kardz_update_list,
} from "./actionTypes";

export const get_list = (index: string) => {
    if (data) {
        let curr: any = data;
        return { type: kardz_update_list, payload: curr[index] };
    }
    return { type: kardz_update_list, payload: [] };
};

export const set_curr_card = (card: string) => {
    return { type: kardz_set_card_name, payload: card };
};

export const get_index_list = () => {
    return { type: kardz_update_index_list, payload: Object.keys(data) };
};

export const toggleLogin = (payload: boolean) => {
    return payload ? { type: "SIGN_IN" } : { type: "SIGN_OUT" };
};

export const userInfo = (payload: object) => {
    return {
        payload: payload,
        type: "USER_LOGIN_INFO",
    };
};
