import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { formatTime } from "./util";

export const Timer = () => {
    const [seconds, setSeconds] = React.useState(0);
    const [isRunning, setIsRunning] = React.useState(true);
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (isRunning) {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isRunning]);

    const handleStart = () => {
        setIsRunning(true);
    };

    const handlePause = () => {
        setIsRunning(false);
    };

    const handleReset = () => {
        setIsRunning(false);
        setSeconds(0);
    };

    return (
        <div className="flex gap-1 bg-gray-200 rounded-md p-1">
            <div className="flex gap-3 justify-center items-center">
                <div className=" flex gap-1 bg-sky-100 rounded-md p-1">
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).hoursString}
                    </div>
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).minutesString}
                    </div>
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).secondsString}
                    </div>
                </div>
            </div>
            <div className="flex gap-2 bg-gray-300 rounded-md p-1">
                <div className="bg-gray-200  rounded-md px-0">
                    {!isRunning ? (
                        <button onClick={handleStart}>
                            <PlayArrowIcon />
                        </button>
                    ) : (
                        <button onClick={handlePause}>
                            <PauseIcon />
                        </button>
                    )}
                </div>
                <div className="bg-gray-200  rounded-md px-0">
                    <button onClick={handleReset}>
                        <RestartAltIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};
