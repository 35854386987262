import { combineReducers } from "redux";
import { kardzReducer } from "./Kardz_Page.ts";
import { koreanReducer } from "./korean.ts";
import { IsLoggedReducer, userInfoReducer } from "./login.ts";

const allReducers = combineReducers({
    kardz: kardzReducer,
    korean: koreanReducer,
    login: IsLoggedReducer,
    user: userInfoReducer,
});

export default allReducers;
