import React from "react";
import NavBar from "./components/nav_bar";

// type Props = {};

const App = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="flex flex-col justify-between items-center w-full h-full">
            <NavBar />
            {children}
            <></>
        </div>
    );
};

export default App;
