import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./app.tsx";

import { store } from "./store/store.tsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { KoreanEditor } from "./pages/korean/korean_editor.tsx";
import { PairMatching } from "./pages/korean/pair_matching.tsx";
import Korean from "./pages/korean/korean.tsx";
import { Kardz } from "./components/kardz.tsx";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Kardz />,
    },
    {
        path: "/korean",
        element: <Korean />,
    },
    {
        path: "/korean/match_pairs",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <PairMatching />
            </div>
        ),
    },
    {
        path: "/korean/editor",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <KoreanEditor />
            </div>
        ),
    },
]);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App>
                <RouterProvider router={router} />
            </App>
            <ToastContainer position="bottom-right" autoClose={1000} />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
