import React from "react";
import { Link } from "react-router-dom";
import Login from "../../components/login";

type Props = {};

const Korean = ({}: Props) => {
    return (
        <div className="flex justify-center items-center h-full w-full flex-col gap-2">
            <div className="font-mono text-5xl">Korean</div>
            <div className="flex flex-col gap-2">
                <Link to="/korean/match_pairs">
                    <div className="bg-sky-300 shadow-indigo-400 p-2 rounded-md font-semibold">
                        Match the Pairs
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Korean;
