import * as React from "react";
import {
    Box,
    Typography,
    Button,
    FormControlLabel,
    Switch,
    Skeleton,
    LinearProgress,
    Drawer,
    Slider,
    MenuItem,
    Checkbox,
    ListItemText,
    InputLabel,
    FormControl,
    Select,
    OutlinedInput,
    SelectChangeEvent,
} from "@mui/material";
import { shuffleArray } from "../../components/utils/random";
import axios from "axios";
import { useKardzAppDispatch, useKardzAppSelector } from "../../store/store";
import { korean_update_words_list } from "../../store/actions/actionTypes";
import { toast } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { categories, slotSchema, speakText, wordSchema } from "./utils/util";
import { Timer } from "./utils/timer";
import {
    kardz_url_list,
    kardz_url_update_meta,
} from "./../../variables";

// V1 only consists of the matching 5 pairs and move ahead..
export function PairMatching() {
    const { all_words } = useKardzAppSelector((state) => state.korean);
    const _user = useKardzAppSelector((state) => state.user) || null;
    const [states, setStates] = React.useState<{
        score: number;
        correct: number;
        wrong: number;
        next: boolean;
        adaptive: boolean;
        progress: number;
        shuffle: boolean;
        shuffle_slots: boolean;
        settings: boolean;
        accuracy_gap: number;
        en_audio: boolean;
        kr_audio: boolean;
        left_visible: boolean;
        category_filter: string[];
    }>({
        score: 0,
        correct: 0,
        wrong: 0,
        next: false,
        adaptive: true,
        progress: 0,
        shuffle: false,
        shuffle_slots: true,
        settings: false,
        accuracy_gap: 1,
        en_audio: true,
        kr_audio: true,
        left_visible: true,
        category_filter: [],
    });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [slots, setSlots] = React.useState<slotSchema[]>([]);
    // const [source, setSource] = React.useState<string>("1600");

    // Adaptive SLots, Wrong Options Will be added here for revisits.
    const [adaptiveSlots, setAdaptiveSlots] = React.useState<wordSchema[]>([]);
    const [remaining_words, setRemaining_words] =
        React.useState<wordSchema[]>(all_words);

    const dispatch = useKardzAppDispatch();
    const dispatch_action = React.useCallback(
        (action: any) => dispatch(action),
        []
    );

    const get_progres_count = (data: any) => {
        let progress = 0;
        data.map((word: wordSchema) => {
            let wrong = word.meta.wrong | 0;
            let correct = word.meta.correct! | 0;
            if (Math.abs(wrong - correct) >= states.accuracy_gap) {
                progress = progress + 1;
            }
            return null;
        });
        setStates({
            ...states,
            progress: Math.floor((progress * 100) / data.length),
        });
    };

    const fetch_words = async () => {
        setLoading(true);
        try {
            const {
                data: { raw, meta },
            } = await axios.post(kardz_url_list, {
                language: "korean",
                email: _user?.user_email || "",
            });
            if (raw.length) {
                let raw_data = raw || [];
                let filtered_data = [...raw_data];

                // merge the metadata
                filtered_data = filtered_data.map((f_data: any) => {
                    for (let i = 0; i < meta.length; i++) {
                        if (f_data._id === meta[i].word_id) {
                            f_data.meta = meta[i];
                            break;
                        }
                    }

                    f_data.meta.word_id = f_data._id;
                    // console.log(f_data.meta.word_id);
                    return { ...f_data };
                });

                // console.log(filtered_data);

                if (states.category_filter.length > 0) {
                    filtered_data = filtered_data.filter(
                        (_data: wordSchema) => {
                            let res = false;
                            for (
                                let i = 0;
                                i < states.category_filter.length;
                                i++
                            ) {
                                const category = states.category_filter[i];
                                res = _data.category.includes(category);
                                if (res) return true;
                            }
                            return res;
                        }
                    );
                }

                get_progres_count(filtered_data);
                dispatch_action({
                    type: korean_update_words_list,
                    payload: [...filtered_data],
                });
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            // throw error; // Rethrow the error for further handling
        }
        setLoading(false);
    };

    const sync_word_server = async (word: any, correct: boolean) => {
        let { meta } = word;

        if (correct) {
            if (!meta.correct) meta.correct = 1;
            else meta.correct = meta.correct + 1;
        } else {
            if (!meta.wrong) meta.wrong = 1;
            else meta.wrong = meta.wrong + 1;
        }

        try {
            await axios.post(kardz_url_update_meta, {
                meta,
                language: "korean",
                email: _user.user_email || null,
            });
            // console.log(meta);
            toast.info("Updated!");
            // dispatch_action({ type: korean_update_words_list, payload: data });
        } catch (error) {
            console.error("Error fetching document:", error);
            // throw error; // Rethrow the error for further handling
        }
    };

    const Init = () => {
        // const _stack = states.adaptive ? 14 : 10;

        // get data
        let words: wordSchema[] = all_words?.length ? [...all_words] : [];

        // Sort words based on correct score. Or Just shuffle

        let refined_words: wordSchema[] = states.shuffle
            ? shuffleArray(words)
            : words.sort(
                  (a, b) =>
                      Math.abs(
                          a.meta.correct / (a.meta.wrong + a.meta.correct)
                      ) -
                      Math.abs(b.meta.correct / (b.meta.wrong + b.meta.correct))
                  //   a.meta.correct - b.meta.correct
              );

        refined_words = refined_words.slice(0, 5);

        if (states.adaptive) {
            const entries = [...adaptiveSlots];
            refined_words = [...refined_words, ...entries.splice(0, 2)];
            // const remainingObject = Object.fromEntries(entries);
            setAdaptiveSlots(entries);
        }
        const _slots = [];
        // console.log(new_pairs, adaptiveSlots);

        let stack = refined_words.length * 2;
        for (let i = 0; i < stack; i++) {
            let idx = Math.floor(i % (stack / 2));
            const slot: slotSchema = {
                id: i,
                value: {
                    left: refined_words[idx].value,
                    right: refined_words[idx].alpha,
                },
                raw: refined_words[idx],
                active: true,
                side: Math.floor(i / (stack / 2)) ? "left" : "right",
                selected: false,
                state: "Idle",
            };
            _slots.push(slot);
        }

        setSlots([
            ..._slots.slice(0, stack / 2),
            ...(states.shuffle_slots
                ? shuffleArray(_slots.slice(stack / 2))
                : _slots.slice(stack / 2)),
        ]);
    };

    const Next = () => {
        // const _stack = states.adaptive ? 14 : 10;

        // get data
        let refined_words: wordSchema[] = [...remaining_words];
        setRemaining_words([...refined_words.slice(5)]);
        refined_words = refined_words.slice(0, 5);

        if (states.adaptive) {
            const entries = [...adaptiveSlots];
            refined_words = [...refined_words, ...entries.splice(0, 2)];
            // const remainingObject = Object.fromEntries(entries);
            setAdaptiveSlots(entries);
        }

        const _slots = [];
        // console.log(new_pairs, adaptiveSlots);

        let stack = refined_words.length * 2;
        for (let i = 0; i < stack; i++) {
            let idx = Math.floor(i % (stack / 2));
            const slot: slotSchema = {
                id: i,
                value: {
                    left: refined_words[idx].value,
                    right: refined_words[idx].alpha,
                },
                raw: refined_words[idx],
                active: true,
                side: Math.floor(i / (stack / 2)) ? "left" : "right",
                selected: false,
                state: "Idle",
            };
            _slots.push(slot);
        }

        setSlots([
            ..._slots.slice(0, stack / 2),
            ...(states.shuffle_slots
                ? shuffleArray(_slots.slice(stack / 2))
                : _slots.slice(stack / 2)),
        ]);
    };

    const makeCleanUp = async () => {
        // console.log("Clean Up Run.");
        if (!slots.length) return;
        setSlots([
            ...slots.map((slot) => {
                if (slot.state === "Correct") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: false,
                        selected: false,
                    } as slotSchema;
                } else if (slot.state === "Wrong") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: true,
                        selected: false,
                    } as slotSchema;
                } else return { ...slot };
            }),
        ]);
        // check for next
        // let is_next = false;
        // let _slots = [...slots]
        // _slots.map((slot) => (!slot.active ? (is_next = true) : null));
        // if (is_next) setStates({ ...states, next: true });
    };

    const handleSelect = ({
        value,
        active,
        state,
        selected,
        side,
        id,
        raw,
    }: slotSchema) => {
        let updated_slots = [];

        let selected_slot_left: any = null;
        let selected_slot_right: any = null;

        // Text To speech
        if (side === "left") {
            speakText(value.left || "", "En", states.en_audio, states.kr_audio);
        } else {
            speakText(
                value.right || "",
                "Kr",
                states.en_audio,
                states.kr_audio
            );
        }

        // check for same value and Uncheck ( Maintain the Single selected)
        updated_slots = slots.map((_slot) => {
            let slot: slotSchema = _slot;
            // consider the Side as its a Break-point
            if (slot.side === side) {
                // selected or not
                if (selected) {
                    if (slot.id === id) {
                        return { ...slot, selected: false };
                    }
                } else {
                    if (slot.id === id) {
                        return { ...slot, selected: true };
                    } else return { ...slot, selected: false };
                }
            }
            return { ...slot };
        });

        // check for current selected wrong or correct
        updated_slots.map((_slot) => {
            let slot = _slot;
            if (slot.selected) {
                if (slot.side === "left") selected_slot_left = slot;
                else selected_slot_right = slot;
            }
            return null;
        });

        // making sure that slots are not null
        if (selected_slot_left && selected_slot_right) {
            if (
                selected_slot_left?.value.left ===
                selected_slot_right?.value.left
            ) {
                // state is correct
                sync_word_server(selected_slot_left.raw, true);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score + 1,
                    correct: states.correct + 1,
                });
            } else {
                // state is Wrong
                // let wrong_obj: Pair = {};
                // wrong_obj[selected_slot_left.value.left] =
                //     selected_slot_left.value.right;
                if (states.adaptive) {
                    setAdaptiveSlots([
                        ...adaptiveSlots,
                        selected_slot_left.raw,
                        selected_slot_right.raw,
                    ]);
                }

                sync_word_server(selected_slot_left.raw, false);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score - 1,
                    wrong: states.wrong + 1,
                });
            }
        }

        setSlots([...updated_slots]);
    };

    const handleReset = () => {
        Init();
    };
    const handleCategoryChange = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        // console.log(value);
        setStates((prevWord) => ({
            ...prevWord,
            category_filter: value as string[],
        }));
    };

    React.useEffect(() => {
        Init();
        setRemaining_words(all_words);
        return () => {};
    }, [all_words]);

    React.useEffect(() => {
        fetch_words();
        return () => {};
    }, [_user]);

    React.useEffect(() => {
        setTimeout(() => {
            makeCleanUp();
        }, 300);
    }, [states]);

    return (
        <Box className="flex flex-col justify-between items-center h-full">
            <div className="p-2 flex flex-row-reverse w-full">
                {/* <Login /> */}
            </div>
            <Box
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    margin: "auto",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    // backgroundColor: "gray",
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>
                    Match The Pairs ✨
                </Typography>
                <Timer />
                <Typography variant="h6" align="center" gutterBottom>
                    <div className="flex gap-2 flex-row">
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={states.adaptive}
                                    onChange={(ada) =>
                                        setStates({
                                            ...states,
                                            adaptive: ada.target.checked,
                                        })
                                    }
                                />
                            }
                            label="Adaptive"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={states.shuffle}
                                    onChange={(ada) =>
                                        setStates({
                                            ...states,
                                            shuffle: ada.target.checked,
                                        })
                                    }
                                />
                            }
                            label="Shuffle"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={states.left_visible}
                                    onChange={(ada) =>
                                        setStates({
                                            ...states,
                                            left_visible: ada.target.checked,
                                        })
                                    }
                                />
                            }
                            label="left"
                        />
                        <SettingsIcon
                            cursor="pointer"
                            onClick={() =>
                                setStates({
                                    ...states,
                                    settings: !states.settings,
                                })
                            }
                        />
                    </div>
                    <Drawer
                        anchor={"right"}
                        open={states.settings}
                        onClose={() =>
                            setStates({ ...states, settings: !states.settings })
                        }
                    >
                        <div className="flex flex-col gap-3 px-7 max-w-[400px]">
                            <div className="flex justify-between py-2 items-center">
                                <div className="font-bold text-lg">
                                    {" "}
                                    Settings
                                </div>
                                <IconButton
                                    className="bg-green-300 p-1"
                                    onClick={() =>
                                        setStates({
                                            ...states,
                                            settings: !states.settings,
                                        })
                                    }
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Box sx={{ width: 300 }}>
                                <Typography id="input-slider" gutterBottom>
                                    Accuracy Gap {states.accuracy_gap}
                                </Typography>
                                <Slider
                                    onChange={(_, val) =>
                                        setStates({
                                            ...states,
                                            accuracy_gap: val as number,
                                        })
                                    }
                                    value={states.accuracy_gap}
                                    aria-label="Temperature"
                                    defaultValue={30}
                                    getAriaValueText={() =>
                                        `${states.accuracy_gap}`
                                    }
                                    valueLabelDisplay="auto"
                                    shiftStep={30}
                                    step={1}
                                    marks
                                    min={0}
                                    max={10}
                                />
                            </Box>
                            <div className="flex gap-2 flex-row">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={states.en_audio}
                                            onChange={(ada) =>
                                                setStates({
                                                    ...states,
                                                    en_audio:
                                                        ada.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="En Audio"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={states.kr_audio}
                                            onChange={(ada) =>
                                                setStates({
                                                    ...states,
                                                    kr_audio:
                                                        ada.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Kr Audio"
                                />
                            </div>
                            <div className="flex gap-2 flex-row">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={states.shuffle}
                                            onChange={(ada) =>
                                                setStates({
                                                    ...states,
                                                    shuffle: ada.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Words Shuffle"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={states.shuffle_slots}
                                            onChange={(ada) =>
                                                setStates({
                                                    ...states,
                                                    shuffle_slots:
                                                        ada.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Slots Shuffle"
                                />
                            </div>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "300px",
                                }}
                            >
                                <Box sx={{ width: "100%", mx: 1 }}>
                                    <LinearProgress
                                        variant="determinate"
                                        color="success"
                                        value={states.progress}
                                    />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary" }}
                                    >{`${Math.round(
                                        states.progress
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            <div className="flex gap-1">
                                <FormControl
                                    size="small"
                                    sx={{ m: 0, width: 200 }}
                                >
                                    <InputLabel id="demo-multiple-checkbox-label">
                                        Tag
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={states.category_filter}
                                        onChange={handleCategoryChange}
                                        input={<OutlinedInput label="Tag" />}
                                        renderValue={(selected) =>
                                            selected.join(", ")
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 25 * 4.5 + 2,
                                                    width: 200,
                                                },
                                            },
                                        }}
                                    >
                                        {categories.map((category) => (
                                            <MenuItem
                                                sx={{
                                                    height: "30px",
                                                    paddingLeft: "5px",
                                                }}
                                                key={category}
                                                value={category}
                                            >
                                                <Checkbox
                                                    checked={states.category_filter.includes(
                                                        category
                                                    )}
                                                />
                                                <ListItemText
                                                    primary={category}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => fetch_words()}
                                    // disabled={!states.next}
                                >
                                    Get Words
                                </Button>
                            </div>
                            <div className="flex gap-1 flex-col overflow-y-scroll h-96">
                                {all_words.map(
                                    (word: wordSchema, i: number) => (
                                        <div
                                            key={word.alpha + i}
                                            className=" flex gap-2 px-1"
                                            style={{
                                                background: `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                                    word
                                                        ? (100 *
                                                              word?.meta
                                                                  .correct) /
                                                          (word?.meta.correct +
                                                              word?.meta.wrong)
                                                        : ""
                                                }%, #f7d7d7 ${
                                                    word
                                                        ? (100 *
                                                              word?.meta
                                                                  .correct) /
                                                          (word?.meta.correct +
                                                              word?.meta.wrong)
                                                        : ""
                                                }%, #f7d7d7)`,
                                            }}
                                        >
                                            <div>{i}</div>
                                            <div>{word.alpha}</div>
                                            <div>{word.value}</div>
                                        </div>
                                    )
                                )}
                            </div>
                            {/* <NewWord /> */}
                        </div>
                    </Drawer>
                </Typography>
                <Typography variant="h5" align="center" gutterBottom>
                    <div className="flex gap-3 justify-center items-center">
                        <div className=" flex gap-1 bg-sky-100 rounded-md p-2">
                            <div className="bg-sky-200  rounded-md px-2">
                                Score :
                            </div>
                            <div className="bg-sky-200  rounded-md px-2">
                                {states.score}
                            </div>
                        </div>
                        <div className=" flex gap-2 bg-gray-100 rounded-md p-2">
                            <div className=" bg-green-300 rounded-md px-2">
                                {states.correct}
                            </div>
                            /
                            <div className="bg-red-300 rounded-md px-2">
                                {states.wrong}
                            </div>
                        </div>
                    </div>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "300px",
                    }}
                >
                    <Box sx={{ width: "100%", mx: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            color="success"
                            value={states.progress}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >{`${Math.round(states.progress)}%`}</Typography>
                    </Box>
                </Box>
                {!loading ? (
                    <div className="flex gap-10 justify-around">
                        <div className="flex flex-col gap-5 ">
                            {slots
                                .filter((slot) => slot.side === "left")
                                .map(
                                    ({
                                        value,
                                        active,
                                        state,
                                        selected,
                                        side,
                                        id,
                                        raw,
                                    }) => (
                                        <Button
                                            style={{
                                                minWidth: "150px",
                                                background: active
                                                    ? ""
                                                    : `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                                          raw
                                                              ? (100 *
                                                                    raw?.meta
                                                                        .correct) /
                                                                (raw?.meta
                                                                    .correct +
                                                                    raw?.meta
                                                                        .wrong)
                                                              : ""
                                                      }%, #f7d7d7 ${
                                                          raw
                                                              ? (100 *
                                                                    raw?.meta
                                                                        .correct) /
                                                                (raw?.meta
                                                                    .correct +
                                                                    raw?.meta
                                                                        .wrong)
                                                              : ""
                                                      }%, #f7d7d7)`,
                                            }}
                                            key={id + side}
                                            disabled={active ? false : true}
                                            variant={`${
                                                selected
                                                    ? "contained"
                                                    : "outlined"
                                            }`}
                                            color={`${
                                                state === "Correct"
                                                    ? "success"
                                                    : state === "Wrong"
                                                    ? "error"
                                                    : "info"
                                            }`}
                                            onClick={() =>
                                                handleSelect({
                                                    value,
                                                    active,
                                                    state,
                                                    selected,
                                                    id,
                                                    side,
                                                    raw,
                                                })
                                            }
                                        >
                                            {states.left_visible ? (
                                                value.left
                                            ) : (
                                                <VisibilityOffIcon fontSize="medium" />
                                            )}
                                        </Button>
                                    )
                                )}
                        </div>
                        <div className="flex flex-col gap-5 ">
                            {slots
                                .filter((slot) => slot.side === "right")
                                .map(
                                    ({
                                        value,
                                        active,
                                        state,
                                        selected,
                                        side,
                                        id,
                                        raw,
                                    }) => (
                                        <div className="flex gap-1">
                                            <Button
                                                style={{
                                                    background: active
                                                        ? ""
                                                        : `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                                              raw
                                                                  ? (100 *
                                                                        raw
                                                                            ?.meta
                                                                            .correct) /
                                                                    (raw?.meta
                                                                        .correct +
                                                                        raw
                                                                            ?.meta
                                                                            .wrong)
                                                                  : ""
                                                          }%, #f7d7d7 ${
                                                              raw
                                                                  ? (100 *
                                                                        raw
                                                                            ?.meta
                                                                            .correct) /
                                                                    (raw?.meta
                                                                        .correct +
                                                                        raw
                                                                            ?.meta
                                                                            .wrong)
                                                                  : ""
                                                          }%, #f7d7d7)`,
                                                }}
                                                key={id + side}
                                                disabled={active ? false : true}
                                                variant={`${
                                                    selected
                                                        ? "contained"
                                                        : "outlined"
                                                }`}
                                                color={`${
                                                    state === "Correct"
                                                        ? "success"
                                                        : state === "Wrong"
                                                        ? "error"
                                                        : "info"
                                                }`}
                                                onClick={() =>
                                                    handleSelect({
                                                        value,
                                                        active,
                                                        state,
                                                        selected,
                                                        side,
                                                        id,
                                                        raw,
                                                    })
                                                }
                                            >
                                                {value.right}
                                            </Button>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                ) : (
                    <div className="flex gap-4 ">
                        {/* <Skeleton variant="rectangular" animation="wave" width={310} height={300} /> */}
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={150}
                            height={300}
                        />
                        <Skeleton
                            variant="rounded"
                            animation="wave"
                            width={150}
                            height={300}
                        />
                    </div>
                )}

                <Box
                    sx={{
                        textAlign: "center",
                        marginTop: 2,
                        display: "flex",
                        gap: "15px",
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleReset}
                        // disabled={!states.next}
                    >
                        Reset
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={Next}
                        // disabled={!states.next}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
            <></>
        </Box>
    );
}
